interface Props {
  face: string
  className?: string
  variant?: 'outlined' | 'round' | 'sharp' | 'two-tone' | ''
  size?: 'xl' | 'lg' | 'md' | 'sm'
}

export const Icon = ({
  className = '',
  face,
  variant = 'round',
  size = 'sm'
}: Props) => {
  return (
    <span className={'flex ' + className}>
      <span
        data-icon={face}
        data-testid="icon"
        // eslint-disable-next-line prettier/prettier
        className={`material-icons${variant ? '-'.concat(variant) : ''} text-icon-${size}`}
      />
    </span>
  )
}
