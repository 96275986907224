import api from 'lib/api'
import { Company } from 'types/company'
import { cookies } from 'lib/cookies'

interface ResponseCompany extends Company {
  colorCompany: string
  colorTextCompany: string
}

export const getCompany = async (slug: string): Promise<Company | null> => {
  if (slug) {
    try {
      const { data } = await api<ResponseCompany>('/home/company/' + slug)
      const hex = data.colorCompany ?? '#601e82'

      return {
        ...data,
        color: {
          hex,
          text: `text-[${data.colorTextCompany ?? '#fff'}]`,
          background: `bg-[${hex}]`
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return null
}

export const setCacheCompany = (company: Company): void => {
  const companyCookie = {
    banner: company.banner,
    banner_mobile: company.banner_mobile,
    color: company.color,
    logo: company.logo,
    name: company.name,
    slug: company.slug,
    referenceId: company.referenceId
  }

  cookies.set('jobs_company', JSON.stringify(companyCookie), { domain: '' })
}

export const applyTalentBankInCompany = async ({
  slug,
  referenceId
}: Company): Promise<void> => {
  const data = { slug, referenceId }
  await api('/applicant/apply-job', { method: 'POST', data }, 'v2')
}
