import env from 'lib/env'

const initScript = () => {
  const script = document.createElement('script')
  const js = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${env.hotjat_id},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `

  script.type = 'text/javascript'
  script.append(js)
  document.head.appendChild(script)
}

export const initHotjar = () => {
  initScript()
}
