import classNames from 'clsx'
import type { Color, Variant } from './types'

const contaierClass = 'flex items-center gap-x-3 p-3 rounded-lg w-full'

export const container = (color: Color, variant: Variant) => {
  if (variant === 'ghost') {
    return classNames(contaierClass, {
      'bg-info-100 text-info-900': color === 'info',
      'bg-success-100 text-success-900': color === 'success',
      'bg-warning-100 text-warning-900': color === 'warning',
      'bg-error-100 text-error-900': color === 'error'
    })
  }

  return classNames(contaierClass, {
    'bg-info-500 text-white': color === 'info',
    'bg-success-700 text-gray-100': color === 'success',
    'bg-warning-700 text-gray-100': color === 'warning',
    'bg-error-500 text-white': color === 'error'
  })
}

export const icon = (color: Color, isGhost: boolean) =>
  classNames({
    'text-info-500': isGhost && color === 'info',
    'text-success-500': isGhost && color === 'success',
    'text-warning-500': isGhost && color === 'warning',
    'text-error-500': isGhost && color === 'error'
  })

export const iconFace = (color: Color) =>
  classNames({
    info: color === 'info',
    check_circle: color === 'success',
    warning: color === 'warning',
    error: color === 'error'
  })

export default { container, icon, iconFace }
