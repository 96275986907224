import api from 'lib/api'
import env from 'lib/env'
import type { User } from 'types/user'
import { KEY_USER_STORAGE } from 'utils/constants'
import { cookies, DOMAIN_COOKIE } from 'lib/cookies'
import { getMetricName } from 'utils/page'

export const handleSignOut = () => {
  const idToken = cookies.get('idToken')
  if (idToken) {
    const keyStorage = KEY_USER_STORAGE + idToken.slice(-5)
    sessionStorage.removeItem(keyStorage)
  }

  const fields = cookies.get()
  Object.entries(fields).forEach(([key]) => cookies.remove(key, DOMAIN_COOKIE))
}

export const handleSignIn = async (): Promise<User> => {
  try {
    const response = await api<User>('/user')

    const keyStorage = KEY_USER_STORAGE + cookies.get('idToken').slice(-5)
    sessionStorage.setItem(keyStorage, JSON.stringify(response.data))

    return response.data
  } catch (err) {
    console.log(err)

    if (err.status === 401) {
      handleSignOut()
    }
  }

  return null
}

const getLinkParamsAuth = () => {
  const metric = getMetricName()
  const search = window.location.search
  const params = search?.includes('applicationSource=')
    ? search
    : `?applicationSource=${metric}${search?.replace('?', '&')}`

  let link = env.host_auth
  if (!params?.includes('applicationSource=default')) link += params

  return link
}

export const handleToAuth = () => {
  const redirect = location.href
  cookies.set('redirect_url', redirect)
  location.href = getLinkParamsAuth()
}

export const userHasAppliedInTalentBank = async (
  companyId: number
): Promise<boolean> => {
  try {
    const response = await api<{ data: { company_id: number }[] }>(
      '/applicant/my-talent-bases',
      {},
      'applicants'
    )
    const hasApplied = response.data.data.find(
      item => item.company_id === companyId
    )
    return !!hasApplied?.company_id
  } catch (err) {
    console.error(err)
  }
  return false
}
