import { ReactNode } from 'react'
import { LayoutHeader } from './header/'
import { LayoutFooter } from './footer'
import { LayoutBanner } from './banner'

export const Layout = ({ children }: { children: ReactNode }) => (
  <div className="flex flex-col min-h-screen items-stretch">
    <LayoutHeader />
    <main className="flex-1 bg-gray-100 relative s-content">
      <LayoutBanner />
      <div className="relative z-[2] w-full flex flex-col">
        {children}
      </div>
    </main>
    <LayoutFooter />
  </div>
)
