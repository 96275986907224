const environments = {
  development: {
    isProduction: false,
    api: {
      v2: 'https://staging.api.solides.jobs/v2',
      v3: 'https://apigwstg.solides.com.br/jobs/v3',
      applicants: 'https://apigwstg.solides.com.br/applicants/jobs/v3'
    },
    google_analytics: 'GTM-5ND9KNF',
    host_profile: 'https://perfil.vagas.staging.solides.com.br',
    host_portal: 'https://vagas.staging.solides.com.br',
    host_auth: 'https://auth.vagas.staging.solides.com.br',
    hotjat_id: '3311665'
  },
  production: {
    isProduction: true,
    api: {
      v2: 'https://api.solides.jobs/v2',
      v3: 'https://apigw.solides.com.br/jobs/v3',
      applicants: 'https://apigw.solides.com.br/applicants/jobs/v3'
    },
    google_analytics: 'GTM-NZVFQCV',
    host_profile: 'https://perfil.vagas.solides.com.br',
    host_portal: 'https://vagas.solides.com.br',
    host_auth: 'https://auth.vagas.solides.com.br',
    hotjat_id: '1323765'
  }
}

type Environment = keyof typeof environments

const key = process?.env?.ENV || 'production'

export default environments[key as Environment]
