import Image from 'next/image'
import { Icon } from 'components'
import { useStore } from 'lib/store'
import { twMerge } from 'tailwind-merge'

interface Props {
  closeMobileMenu: () => void
  navigation: {
    url: string
    icon: string
    name: string
  }[]
}

export const MobileMenu = ({ closeMobileMenu, navigation }: Props) => {
  const { company, user, signOut } = useStore()

  const handleSignOut = () => {
    signOut()
    closeMobileMenu()
  }

  return (
    <div className="flex flex-col gap-4 pl-4 pt-4 border-t-black/10 border-t">
      <div className="flex items-center gap-2.5">
        <div
          className={twMerge(
            'w-10 h-10 relative flex rounded-full overflow-hidden',
            company.color.background
          )}
        >
          {user.picture ? (
            <Image
              src={user.picture}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              alt="Foto de avatar do usuário"
            />
          ) : (
            <Icon
              face="person_outline"
              size="md"
              className={twMerge(company.color.text, 'm-auto')}
            />
          )}
        </div>

        <div className="flex flex-col">
          <p className="text-subtitle2 leading-tight">{user.name}</p>
          <span className="text-gray-600 break-words text-caption leading-tight">
            {user.email}
          </span>
        </div>
      </div>

      {navigation.map(navItem => (
        <a
          href={navItem.url}
          key={navItem.url + navItem.name}
          className="w-full flex items-center gap-x-4 pl-[7px]"
        >
          <Icon
            size="md"
            face={navItem.icon}
            className={`text-[${company.color.hex}]`}
          />
          <span className="text-body2">{navItem.name}</span>
        </a>
      ))}

      <button
        onClick={handleSignOut}
        className="w-full flex items-center gap-x-4 pl-[7px]"
      >
        <Icon
          size="md"
          face="exit_to_app"
          className={`text-[${company.color.hex}]`}
        />
        <span className="text-body2">Sair</span>
      </button>
    </div>
  )
}
