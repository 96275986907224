export const IconProfilerGroup = () => (
  <svg
    width="218"
    height="66"
    viewBox="0 0 218 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.1886"
      y="2.44251"
      width="60.1863"
      height="60.1863"
      rx="30.0931"
      fill="#CA243C"
    />
    <rect
      x="3.1886"
      y="2.44251"
      width="60.1863"
      height="60.1863"
      rx="30.0931"
      stroke="#F5F7F9"
      strokeWidth="4.88502"
    />
    <path
      d="M28.0286 40.623V24.6653H38.8115V27.6745H31.3569V37.6139H38.9711V40.623H28.0286ZM29.6244 33.9436V31.0256H37.7172V33.9436H29.6244Z"
      fill="white"
    />
    <rect
      x="53.6671"
      y="2.44251"
      width="60.1863"
      height="60.1863"
      rx="30.0931"
      fill="#2B5DE9"
    />
    <rect
      x="53.6671"
      y="2.44251"
      width="60.1863"
      height="60.1863"
      rx="30.0931"
      stroke="#F5F7F9"
      strokeWidth="4.88502"
    />
    <path
      d="M75.8005 40.623L82.0469 24.6653H85.1016L91.3251 40.623H87.8144L84.6001 32.0287C84.5089 31.7855 84.3949 31.4739 84.2581 31.094C84.1366 30.7141 84.0074 30.3113 83.8706 29.8858C83.7338 29.4602 83.6046 29.0575 83.4831 28.6775C83.3615 28.2824 83.2703 27.9556 83.2095 27.6973L83.825 27.6745C83.7338 28.024 83.6274 28.3888 83.5058 28.7687C83.3995 29.1335 83.2855 29.5134 83.1639 29.9086C83.0423 30.2885 82.9131 30.6609 82.7764 31.0256C82.6396 31.3904 82.5104 31.7475 82.3888 32.0971L79.1745 40.623H75.8005ZM78.6957 37.2719L79.8128 34.5135H87.1305L88.2704 37.2719H78.6957Z"
      fill="white"
    />
    <rect
      x="104.146"
      y="2.44251"
      width="60.1863"
      height="60.1863"
      rx="30.0931"
      fill="#18A764"
    />
    <rect
      x="104.146"
      y="2.44251"
      width="60.1863"
      height="60.1863"
      rx="30.0931"
      stroke="#F5F7F9"
      strokeWidth="4.88502"
    />
    <path
      d="M128.172 40.623V24.6653H134.943C135.885 24.6653 136.736 24.8933 137.496 25.3492C138.271 25.7899 138.879 26.3979 139.32 27.173C139.776 27.948 140.004 28.8219 140.004 29.7946C140.004 30.7824 139.776 31.6715 139.32 32.4618C138.879 33.2521 138.271 33.8828 137.496 34.3539C136.736 34.8099 135.885 35.0378 134.943 35.0378H131.592V40.623H128.172ZM131.546 31.9603H134.555C134.92 31.9603 135.247 31.8691 135.536 31.6867C135.824 31.4891 136.052 31.2308 136.219 30.9116C136.402 30.5925 136.493 30.2277 136.493 29.8174C136.493 29.407 136.402 29.0499 136.219 28.7459C136.052 28.4268 135.824 28.1836 135.536 28.0164C135.247 27.8341 134.92 27.7429 134.555 27.7429H131.546V31.9603Z"
      fill="white"
    />
    <rect
      x="154.624"
      y="2.44251"
      width="60.1863"
      height="60.1863"
      rx="30.0931"
      fill="#FEB92C"
    />
    <rect
      x="154.624"
      y="2.44251"
      width="60.1863"
      height="60.1863"
      rx="30.0931"
      stroke="#F5F7F9"
      strokeWidth="4.88502"
    />
    <path
      d="M185.681 41.6137C184.51 41.6137 183.431 41.4161 182.443 41.021C181.471 40.6107 180.62 40.0407 179.89 39.3112C179.176 38.5665 178.621 37.6927 178.226 36.6896C177.831 35.6714 177.633 34.5619 177.633 33.3613C177.633 32.191 177.838 31.112 178.249 30.1241C178.659 29.1211 179.237 28.2548 179.981 27.5253C180.726 26.7806 181.6 26.2031 182.603 25.7928C183.606 25.3824 184.693 25.1772 185.863 25.1772C186.623 25.1772 187.367 25.2836 188.097 25.4964C188.842 25.694 189.518 25.9903 190.126 26.3855C190.749 26.7806 191.281 27.2517 191.722 27.7989L189.579 30.1697C189.214 29.805 188.834 29.4934 188.439 29.2351C188.059 28.9615 187.649 28.7563 187.208 28.6196C186.782 28.4676 186.326 28.3916 185.84 28.3916C185.187 28.3916 184.571 28.5132 183.994 28.7563C183.431 28.9995 182.937 29.3414 182.512 29.7822C182.086 30.2077 181.744 30.7321 181.486 31.3552C181.243 31.9631 181.121 32.6394 181.121 33.3841C181.121 34.144 181.243 34.8355 181.486 35.4586C181.729 36.0665 182.071 36.5908 182.512 37.0316C182.953 37.4571 183.477 37.7915 184.085 38.0346C184.708 38.2626 185.392 38.3766 186.136 38.3766C186.623 38.3766 187.094 38.3082 187.55 38.1714C188.006 38.0346 188.424 37.8446 188.804 37.6015C189.199 37.3583 189.548 37.0772 189.852 36.758L191.517 39.4252C191.152 39.8356 190.658 40.2079 190.035 40.5423C189.427 40.8766 188.743 41.1426 187.983 41.3401C187.223 41.5225 186.456 41.6137 185.681 41.6137Z"
      fill="white"
    />
  </svg>
)
