import Link from 'next/link'
import classNames from 'clsx'
import { Tooltip } from '@solides/management-util-ui'

import { useStore } from 'lib/store'
import { Vacancy } from 'types/vacancy'
import { Box, Button, Icon, VacancyBadges } from 'components'
import { getDateDistanceToNow, getDateInLongText } from 'utils/date'

export const VacancyCard = (vacancy: Vacancy) => {
  const user = useStore(store => store.user)
  const isApplied = user && vacancy.applied

  return (
    <Box className="flex flex-col gap-4 p-4 shadow-sm relative">
      {isApplied && (
        <span className="absolute top-0 left-0 w-full bg-success/20 rounded-t-md py-2 text-center text-caption text-success-700">
          Sua candidatura foi realizada para esta vaga
        </span>
      )}

      <p
        className={classNames(
          'leading-5 text-subtitle2 line-clamp-2',
          isApplied && 'mt-8'
        )}
      >
        <Link href={`/vaga/${vacancy.id}`} title={vacancy.title}>
          {vacancy.title}
        </Link>
      </p>

      <div className="flex flex-wrap items-start gap-x-4 gap-y-2 text-gray-900">
        <p className="flex items-center gap-x-[6px] text-gray-900 text-caption">
          <Icon face="location_on" />
          {vacancy.address.short}
        </p>

        {vacancy.salary !== 'Não informado' && (
          <p className="flex items-center gap-x-2 text-gray-900 text-caption">
            <Icon face="monetization_on" /> {vacancy.salary}
          </p>
        )}

        {(vacancy.pcdOnly || vacancy?.peopleWithDisabilities) && (
          <p className="flex items-center gap-x-2 text-gray-900 text-caption">
            <Icon face="accessibility_new" />
            {vacancy.pcdOnly ? 'Exclusivo' : 'Elegível'} PCD
          </p>
        )}

        {!!vacancy.affirmative.length && (
          <Tooltip
            position="top"
            message={vacancy.affirmative.map(af => af.name).join(', ')}
          >
            <p className="flex items-center gap-x-2 text-gray-900 text-caption">
              <Icon face="diversity_3" /> Vaga afirmativa
            </p>
          </Tooltip>
        )}
      </div>

      <VacancyBadges vacancy={vacancy} isComplete={false} />

      {vacancy.createdAt && (
        <time
          dateTime={vacancy.createdAt}
          className="text-caption text-gray-600"
          title={'Postada em '.concat(getDateInLongText(vacancy.createdAt))}
        >
          Postada {getDateDistanceToNow(vacancy.createdAt)}
        </time>
      )}

      <span className="mt-auto">
        <Button href={`/vaga/${vacancy.id}`} className="mt-1.5" colorCompany>
          Ver Vaga
        </Button>
      </span>
    </Box>
  )
}
