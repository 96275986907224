import env from 'lib/env'
import Head from 'next/head'
import { useStore } from 'lib/store'
import { useRouter } from 'next/router'

export interface SEOProps {
  tabName: string
  title: string
  description: string
  image?: string
  keywords?: string
  noIndex?: boolean
  googleForJobs?: null | object
}

export function SEO({
  tabName,
  title,
  description,
  image,
  keywords = '',
  noIndex = false,
  googleForJobs = null
}: SEOProps): JSX.Element {
  const { asPath } = useRouter()
  const company = useStore(store => store.company)

  const URL = `https://${company.slug}.vagas.solides.com.br${asPath}`

  return (
    <Head>
      <title>{tabName}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={URL} />

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />

      {env.isProduction && <link rel="canonical" href={URL} />}

      {image && (
        <>
          <meta property="og:image" content={image} />
          <meta property="og:image:width" content="1200" />

          <meta name="twitter:image" content={image} />
          <meta name="twitter:image:alt" content={title} />
          <meta name="twitter:card" content="summary_large_image" />
        </>
      )}

      {(!env.isProduction || noIndex) && (
        <meta name="robots" content="noindex" />
      )}

      {googleForJobs && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(googleForJobs)
          }}
        />
      )}
    </Head>
  )
}
