import {
  IconFacebook,
  IconInstagram,
  IconLinkedin,
  IconTiktok,
  IconYoutube,
  Logo
} from 'components'
import { useRouter } from 'next/router'

export const LayoutFooter = () => {
  const router = useRouter()

  const isRecommendationPage = router.asPath.includes('indicacao')

  const links = [
    {
      label: 'Link para a página do linkedin da sólides',
      href: 'https://www.linkedin.com/company/solidestecnologia',
      icon: <IconLinkedin />
    },
    {
      label: 'Link para a página do facebook da sólides',
      href: 'https://pt-br.facebook.com/solidesoficial/',
      icon: <IconFacebook />
    },
    {
      label: 'Link para a página do instagram da sólides',
      href: 'https://www.instagram.com/solidesoficial/',
      icon: <IconInstagram />
    },
    {
      label: 'Link para a página do youtube da sólides',
      href: 'https://www.youtube.com/c/SolidesTecnologia',
      icon: <IconYoutube />
    },
    {
      label: 'Link para a página do tiktok da sólides',
      href: 'https://www.tiktok.com/@solidesvagas',
      icon: <IconTiktok />
    }
  ]

  if (isRecommendationPage) {
    return <></>
  }

  return (
    <footer className="flex items-center px-4 py-10 s-footer">
      <div className="w-full max-w-7xl mx-auto flex flex-col sm:flex-row items-center sm:items-end gap-2 justify-between">
        <div className="flex flex-col items-center sm:items-start gap-y-6 sm:gap-y-8 [&>svg]:w-auto [&>svg]:h-8">
          <Logo />
          <p className="text-caption text-gray-800">
            Sólides, tudo que o RH precisa em um só lugar
          </p>
        </div>

        <div className="flex flex-col-reverse sm:flex-col items-center sm:items-end gap-y-4 sm:gap-y-5">
          <div className="flex items-center gap-3">
            {links.map(link => (
              <a
                key={link.href}
                href={link.href}
                target="_blank"
                rel="noreferrer"
                aria-label={link.label}
                className="opacity-70 hover:opacity-100 hover:-translate-y-1 transition-all duration-500"
              >
                {link.icon}
              </a>
            ))}
          </div>
          <div className="text-caption text-gray-800 flex items-center gap-2">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ajuda.solides.com.br/hc/pt-br"
              aria-label="Link para a página de central de ajuda da sólides"
            >
              Central de ajuda
            </a>
            |
            <a
              target="_blank"
              rel="noreferrer"
              href="https://ajuda.solides.com.br/hc/pt-br"
              aria-label="Link para a página de suporte do candidato da sólides"
            >
              Suporte ao candidato
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}
