export const IconGlassdoor = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#212B36"
      d="M11.7583 3.65025e-06C11.7437 -0.00143306 11.7288 0.000286005 11.7149 0.00504541C11.7009 0.00980481 11.6882 0.0174944 11.6774 0.0275977C11.6667 0.0377011 11.6583 0.0499844 11.6527 0.0636227C11.6471 0.0772611 11.6445 0.0919388 11.645 0.10667V2.98167C11.645 3.03834 11.69 3.08167 11.7458 3.08834C13.9292 3.23167 15.5875 3.88 15.5875 6.15584H10.905C10.8902 6.15573 10.8755 6.15856 10.8618 6.16417C10.8481 6.16979 10.8357 6.17807 10.8252 6.18854C10.8147 6.19901 10.8064 6.21146 10.8008 6.22515C10.7952 6.23885 10.7924 6.25353 10.7925 6.26834V13.7383C10.7925 13.8008 10.8425 13.8508 10.905 13.8508H19.2408C19.3033 13.8508 19.3533 13.8008 19.3533 13.7383V6.11834C19.3533 4.25417 18.6867 2.73834 17.34 1.67834C16.1 0.705837 14.24 0.11917 11.7583 0.000836984M0.75833 6.15667C0.743595 6.15667 0.729006 6.15959 0.715403 6.16525C0.701801 6.17091 0.689453 6.17921 0.679073 6.18967C0.668693 6.20013 0.660485 6.21254 0.654923 6.22618C0.64936 6.23983 0.646553 6.25444 0.646663 6.26917V13.7325C0.646663 13.795 0.696663 13.845 0.759163 13.845H5.44166C5.44166 16.1208 3.78333 16.77 1.6 16.9125C1.57331 16.9148 1.5484 16.9268 1.53009 16.9464C1.51178 16.9659 1.50135 16.9916 1.50083 17.0183V19.8942C1.50083 19.9567 1.55083 20.0067 1.61333 20C4.095 19.8817 5.95333 19.295 7.19416 18.3225C8.54083 17.2625 9.2075 15.7475 9.2075 13.8825V6.26834C9.20761 6.25353 9.20477 6.23885 9.19916 6.22515C9.19354 6.21146 9.18526 6.19901 9.17479 6.18854C9.16432 6.17807 9.15188 6.16979 9.13818 6.16417C9.12448 6.15856 9.1098 6.15573 9.095 6.15584L0.75833 6.15667Z"
    />
  </svg>
)
