import { twMerge } from 'tailwind-merge'
import { Icon } from 'components'
import * as Dialog from '@radix-ui/react-dialog'

interface ModalProps extends Dialog.DialogProps {
  size?: string
  className?: string
  onClose?: () => void | null
  children: React.ReactNode
  trigger?: React.ReactNode
}

export const Modal = ({
  children,
  onClose = null,
  className = '',
  trigger = null,
  size = '80vw',
  ...rest
}: ModalProps) => (
  <Dialog.Root {...rest}>
    {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}

    <Dialog.Portal>
      <Dialog.Overlay className="bg-gray-300/60 backdrop-blur-[2px] data-[state=open]:animate-overlayShow fixed inset-0 z-40" />
      <Dialog.Content
        className={`s-modal z-40 data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] w-full max-w-[90vw] lg:max-w-[${size}] translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white shadow-xl focus:outline-none`}
      >
        <div
          className={twMerge(
            'max-h-[85vh] overflow-y-auto scroll px-6 py-8',
            className
          )}
        >
          {children}
        </div>

        {onClose && (
          <Dialog.DialogClose asChild onClick={onClose}>
            <button
              aria-label="Close"
              className="text-gray-700 absolute top-5 right-5 inline-flex h-[25px] w-[25px] appearance-none"
            >
              <Icon face="close" className="m-auto" />
            </button>
          </Dialog.DialogClose>
        )}
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
)
