import { useMemo } from 'react'
import { Badge } from '@solides/management-util-ui'
import { Vacancy } from 'types/vacancy'

const jobsTypeOptions = [
  { text: 'Presencial', value: 'presencial' },
  { text: 'Remoto', value: 'remoto' },
  { text: 'Híbrido', value: 'hibrido' }
]

interface BadgesProps {
  vacancy: Vacancy
  isComplete?: boolean
}

const getLabelInList = list => {
  return list.map(item => item?.text || item?.name)
}

export const VacancyBadges = ({ isComplete = true, vacancy }: BadgesProps) => {
  const badges = useMemo(() => {
    const result: string[] = []

    if (vacancy.showModality && isComplete) {
      result.push(...getLabelInList(vacancy.recruitmentContractType))
    }

    const jobType = jobsTypeOptions.find(type => type.value === vacancy.jobType)
    jobType && result.push(jobType.text)
    result.push(...getLabelInList(vacancy.seniority))
    isComplete && result.push(...getLabelInList(vacancy.affirmative))
    isComplete && result.push(...getLabelInList(vacancy.occupationAreas))

    return result
  }, [vacancy])

  return (
    <div className="flex flex-wrap gap-2">
      {badges.map(badge => (
        <Badge
          color="info"
          variant="ghost"
          key={badge}
          className="rounded capitalize"
        >
          {badge}
        </Badge>
      ))}
    </div>
  )
}
