import { Modal } from '../_UI'
import { Icon, Button } from 'components'
import * as Dialog from '@radix-ui/react-dialog'

interface Button {
  label?: string
  loading?: boolean
  action: () => void
}

interface Props {
  title: string | React.ReactNode
  description?: string | React.ReactNode
  icon?: string | React.ReactNode
  iconVariant?: 'round' | 'outlined'
  open: boolean
  cancelButton?: Button
  confirmButton: Button
  size?: string
  className?: string
}

export const ModalAction = ({
  title,
  description = '',
  icon = '',
  iconVariant = 'outlined',
  cancelButton = null,
  confirmButton,
  open,
  size = '360px',
  className = ''
}: Props) => (
  <Modal
    open={open}
    className={`flex flex-col gap-y-4 items-center ${className}`}
    size={size}
  >
    {icon && typeof icon === 'string' ? (
      <Icon
        face={icon}
        size="xl"
        className="text-primary"
        variant={iconVariant}
      />
    ) : (
      icon
    )}

    <Dialog.Title className="text-gray-900 text-center text-sm font-semibold">
      {title}
    </Dialog.Title>

    {description && (
      <Dialog.Description className="text-gray-700 text-center text-body1">
        {description}
      </Dialog.Description>
    )}

    <div className="mt-4 w-full flex gap-x-4 [&>button]:shadow-none [&>button]:w-full [&>button]:px-0 [&>button]:justify-center">
      {cancelButton && (
        <Button
          onClick={cancelButton.action}
          className="bg-transparent text-primary !border !border-primary-700/50"
        >
          {cancelButton?.label || 'Cancelar'}
        </Button>
      )}
      <Button onClick={confirmButton.action} loading={confirmButton?.loading}>
        {confirmButton?.label || 'Confirmar'}
      </Button>
    </div>
  </Modal>
)
