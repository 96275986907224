import { Toast } from '@solides/management-util-ui'
import React, { createContext, useContext, useState } from 'react'

interface ToastProviderProps {
  children: React.ReactNode
}

interface ToastProps {
  title: string
  description?: string
  color: string
}

export interface ToastContextData {
  success: (t: Omit<ToastProps, 'color'>) => void
  error: (t: Omit<ToastProps, 'color'>) => void
}

declare type ToastColor = 'info' | 'warning' | 'success' | 'error'

const ToastContext = createContext({} as ToastContextData)

const DEFAULT_DATA = {
  title: '',
  description: '',
  color: ''
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [toast, setToast] = useState<ToastProps>(DEFAULT_DATA)

  const createToast = (data: ToastProps): void => setToast(data)

  return (
    <ToastContext.Provider
      value={{
        error: t => createToast({ color: 'error', ...t }),
        success: t => createToast({ color: 'success', ...t })
      }}
    >
      {children}
      <Toast
        isOpen={toast.title !== ''}
        renderTime={3000}
        title={toast.title}
        description={toast.description}
        color={toast.color as ToastColor}
        onClose={() => setToast(DEFAULT_DATA)}
        closeButton
      />
    </ToastContext.Provider>
  )
}

export const useToast = () => {
  const context = useContext(ToastContext)

  return context
}
