import Cookies from 'js-cookie'

export const DOMAIN_COOKIE = { domain: 'solides.com.br' }

const cookies = Cookies.withAttributes({
  path: '/',
  expires: 20 / (24 * 60), // 20 minutos
  secure: true,
  SameSite: 'None',
  domain: DOMAIN_COOKIE.domain
})

function generateUuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const init = () => {
  if (!window.name) {
    window.name = cookies.get('redirectCurrentWindow') || generateUuid()
  }
}

export const redirect = (url: string) => {
  // Adicioando essa validação por conta do iphone trocar a key da aba
  cookies.set('redirectCurrentWindow', window.name)
  window.location.href = url
}

export { cookies }
