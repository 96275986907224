export const IconInstagram = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00391 4.50781C6.51953 4.50781 4.51562 6.51172 4.51562 8.99609C4.51562 11.4805 6.51953 13.4844 9.00391 13.4844C11.4883 13.4844 13.4922 11.4805 13.4922 8.99609C13.4922 6.51172 11.4883 4.50781 9.00391 4.50781ZM9.00391 11.9141C7.39844 11.9141 6.08594 10.6055 6.08594 8.99609C6.08594 7.38672 7.39453 6.07812 9.00391 6.07812C10.6133 6.07812 11.9219 7.38672 11.9219 8.99609C11.9219 10.6055 10.6094 11.9141 9.00391 11.9141ZM14.7227 4.32422C14.7227 4.90625 14.2539 5.37109 13.6758 5.37109C13.0938 5.37109 12.6289 4.90234 12.6289 4.32422C12.6289 3.74609 13.0977 3.27734 13.6758 3.27734C14.2539 3.27734 14.7227 3.74609 14.7227 4.32422ZM17.6953 5.38672C17.6289 3.98437 17.3086 2.74219 16.2812 1.71875C15.2578 0.695312 14.0156 0.375 12.6133 0.304687C11.168 0.222656 6.83594 0.222656 5.39062 0.304687C3.99219 0.371094 2.75 0.691406 1.72266 1.71484C0.695313 2.73828 0.378906 3.98047 0.308594 5.38281C0.226562 6.82812 0.226562 11.1602 0.308594 12.6055C0.375 14.0078 0.695313 15.25 1.72266 16.2734C2.75 17.2969 3.98828 17.6172 5.39062 17.6875C6.83594 17.7695 11.168 17.7695 12.6133 17.6875C14.0156 17.6211 15.2578 17.3008 16.2812 16.2734C17.3047 15.25 17.625 14.0078 17.6953 12.6055C17.7773 11.1602 17.7773 6.83203 17.6953 5.38672ZM15.8281 14.1562C15.5234 14.9219 14.9336 15.5117 14.1641 15.8203C13.0117 16.2773 10.2773 16.1719 9.00391 16.1719C7.73047 16.1719 4.99219 16.2734 3.84375 15.8203C3.07812 15.5156 2.48828 14.9258 2.17969 14.1562C1.72266 13.0039 1.82813 10.2695 1.82813 8.99609C1.82813 7.72266 1.72656 4.98437 2.17969 3.83594C2.48438 3.07031 3.07422 2.48047 3.84375 2.17187C4.99609 1.71484 7.73047 1.82031 9.00391 1.82031C10.2773 1.82031 13.0156 1.71875 14.1641 2.17187C14.9297 2.47656 15.5195 3.06641 15.8281 3.83594C16.2852 4.98828 16.1797 7.72266 16.1797 8.99609C16.1797 10.2695 16.2852 13.0078 15.8281 14.1562Z"
      fill="#212B36"
    />
  </svg>
)
