import Link from 'next/link'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'
import { useEffect, useMemo, useState } from 'react'

import env from 'lib/env'
import { Button } from 'components'
import { useStore } from 'lib/store'
import { MobileMenu } from './mobile-menu'
import { AvatarDropdownMenu } from './avatar-dropdown-menu'
import { handleToAuth } from 'services/user'

export const LayoutHeader = () => {
  const router = useRouter()
  const { company, user, isLoadingUser } = useStore(store => store)
  const companyLogo = company?.logo
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)

  const isRecommendationPage = router.asPath.includes('indicacao')

  useEffect(() => {
    const valueOverflow = isOpenMenu ? 'hidden' : 'auto'
    document.body.style.overflow = valueOverflow
  }, [isOpenMenu])

  const navigation = useMemo(() => {
    return [
      {
        name: 'Perfil',
        icon: 'account_circle',
        url: env.host_profile
      },
      {
        name: 'Portal de vagas',
        icon: 'public',
        url: env.host_portal
      },
      {
        name: 'Meu currículo',
        icon: 'work_outline',
        url: env.host_profile.concat('/curriculo')
      },
      {
        name: 'Meu Profiler',
        icon: 'recent_actors',
        url: env.host_profile.concat('/meu-profiler')
      },
      {
        name: 'Minhas candidaturas',
        icon: 'campaign',
        url: env.host_profile.concat('/applications')
      },
      {
        name: 'Minhas vagas',
        icon: 'favorite_border',
        url: env.host_portal.concat('/minhas-vagas')
      },
      {
        name: 'Configurações',
        icon: 'settings',
        url: env.host_auth.concat('/preferences/settings-account')
      }
    ]
  }, [env])

  return (
    <header className="flex items-center px-4 py-2 border-b border-b-gray-500/25 s-header">
      <div className="w-full max-w-7xl mx-auto flex items-center">
        {companyLogo && (
          <Link href="/" className="h-12 mr-auto">
            <Image
              src={companyLogo}
              width={400}
              height={400}
              alt="logo da empresa"
              quality={100}
              priority
              className="w-auto max-h-12 h-full object-cover"
            />
          </Link>
        )}

        {!isRecommendationPage && (
          <nav
            className={twMerge(
              'flex flex-col items-start gap-4 pt-4 s-nav translate-x-full fixed top-[60px] bottom-0 left-0 w-full z-50 bg-white transition-transform duration-500',
              'md:flex-row md:items-center md:gap-10 md:relative md:w-auto md:top-auto md:h-auto md:z-0 md:p-0 md:translate-x-0',
              isOpenMenu && 'translate-x-0'
            )}
          >
            <a
              href="/#quem-somos"
              className="text-gray-700 text-body2 pl-4 sm:pl-0"
            >
              A empresa
            </a>
            <a
              href="/#banco-de-talentos"
              className="text-gray-700 text-body2 pl-4 sm:pl-0"
            >
              Banco de talentos
            </a>

            {user ? (
              <>
                <span className="hidden md:block -ml-2">
                  <AvatarDropdownMenu navigation={navigation} />
                </span>
                <span className="block md:hidden w-full">
                  <MobileMenu
                    navigation={navigation}
                    closeMobileMenu={() => setIsOpenMenu(false)}
                  />
                </span>
              </>
            ) : (
              <Button
                onClick={handleToAuth}
                loading={isLoadingUser}
                className="mt-10 ml-4 w-[91vw] sm:m-0 sm:w-auto"
                colorCompany
              >
                Entrar
              </Button>
            )}
          </nav>
        )}

        <button
          type="button"
          className="menu-toggle"
          aria-label="Botão para exibir ou ocultar o menu mobile"
          aria-pressed={isOpenMenu}
          onClick={() => setIsOpenMenu(state => !state)}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
    </header>
  )
}
