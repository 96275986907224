import Image from 'next/image'
import { useMemo } from 'react'
import { useStore } from 'lib/store'
import { useRouter } from 'next/router'

export const LayoutBanner = () => {
  const router = useRouter()
  const company = useStore(store => store.company)
  const isRecommendationPage = router.asPath.includes('indicacao')

  const isMobileBanner =
    typeof window !== 'undefined' && window?.innerWidth <= 768

  const bannerImageUrl = useMemo(() => {
    if (isRecommendationPage) {
      return '/banner_recommendation.jpg'
    } else if (company.banner_mobile && isMobileBanner) {
      return company.banner_mobile
    }
    return company.banner ?? ''
  }, [company, isRecommendationPage, isMobileBanner])

  if (router.asPath.includes('/vaga/')) {
    return <></>
  }

  return (
    <div
      className={`w-full h-56 md:h-[300px] absolute top-0 left-0 right-0 ${company.color.background}`}
    >
      {bannerImageUrl && (
        <Image
          fill
          src={bannerImageUrl}
          alt="Banner da empresa"
          className="transition-all duration-500 group-hover:scale-110 object-cover object-top"
          loading="lazy"
          quality={100}
          sizes="1920px"
        />
      )}
    </div>
  )
}
