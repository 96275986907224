import env from 'lib/env'
import { cookies } from 'lib/cookies'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

type VersionApi = keyof typeof env.api

export default async function api<T = unknown, D = unknown>(
  route: string,
  params?: AxiosRequestConfig,
  version: VersionApi = 'v3'
) {
  const isV2 = version === 'v2'
  const idToken = cookies.get(isV2 ? 'token' : 'idToken') || ''

  const options: AxiosRequestConfig = {
    method: 'GET',
    baseURL: env.api[version],
    headers: {},
    ...params
  }

  if (isV2) {
    options.headers.jtoken = idToken
  } else if (idToken) {
    options.headers.authorization = `Bearer ${idToken}`
  }

  const data: AxiosResponse<T, D> = await axios(route, options)

  return data
}
