import Image from 'next/image'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'
import { useEffect, useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { Icon } from 'components'
import { useStore } from 'lib/store'

const classNavItem =
  'rounded-lg hover:cursor-pointer hover:bg-gray-200 focus:bg-gray-200 houver:outline-none focus:outline-gray-300 transition duration-300 ease-in-out'

const NavItem = ({ url, icon, name, color }) => (
  <DropdownMenu.Item className="rounded-lg hover:cursor-pointer hover:bg-gray-200 focus:bg-gray-200 houver:outline-none focus:outline-gray-300 transition duration-300 ease-in-out">
    <a href={url} className="w-full flex items-center gap-x-4 p-3 rounded-lg">
      <Icon face={icon} className={`text-[${color}]`} />
      <span className="text-body2">{name}</span>
    </a>
  </DropdownMenu.Item>
)

interface Props {
  navigation: {
    url: string
    icon: string
    name: string
  }[]
}

export const AvatarDropdownMenu = ({ navigation }: Props) => {
  const router = useRouter()
  const { company, user, signOut } = useStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setIsOpen(false)
  }, [router.pathname])

  return (
    <DropdownMenu.Root onOpenChange={setIsOpen} open={isOpen} modal={false}>
      <DropdownMenu.Trigger
        className={twMerge(
          'w-11 h-11 relative flex rounded-full overflow-hidden',
          company.color.background
        )}
      >
        {user.picture ? (
          <Image
            src={user.picture}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            alt="Foto de avatar do usuário"
          />
        ) : (
          <Icon
            size="md"
            face="person_outline"
            className={twMerge(company.color.text, 'm-auto')}
          />
        )}
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          style={{ zIndex: 20 }}
          align="end"
          sideOffset={5}
          className="flex flex-col items-stretch bg-white rounded-lg pb-2 w-56 shadow-2xl shadow-gray-500/40 z-30"
        >
          <DropdownMenu.Group className="pl-5 py-4">
            <DropdownMenu.Label className="text-subtitle2">
              {user.name}
            </DropdownMenu.Label>
            <DropdownMenu.Label className="text-gray-600 break-words pr-3.5 text-caption">
              {user.email}
            </DropdownMenu.Label>
          </DropdownMenu.Group>

          <DropdownMenu.DropdownMenuSeparator className="bg-[rgba(145,158,171,0.24)] h-[1px]" />

          <DropdownMenu.Group className="flex flex-col mt-2 px-2">
            {navigation.map(item => (
              <NavItem
                {...item}
                key={item.url + item.name}
                color={company.color.hex}
              />
            ))}

            <DropdownMenu.Item
              onClick={signOut}
              className={twMerge(
                'w-full flex items-center gap-x-4 p-3',
                classNavItem
              )}
            >
              <Icon
                face="exit_to_app"
                className={`text-[${company.color.hex}]`}
              />
              <span className="text-body2">Sair</span>
            </DropdownMenu.Item>
          </DropdownMenu.Group>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
