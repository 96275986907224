export const IconEmptyVacancies = ({ className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="430"
    height="431"
    fill="none"
    viewBox="0 0 430 431"
    className={className}
  >
    <g clipPath="url(#clip0_19942_17131)">
      <rect
        width="176.537"
        height="396.065"
        x="298.633"
        y="41"
        fill="#DFE3E8"
        rx="88.269"
        transform="rotate(45 298.633 41)"
      ></rect>
      <g fill="#601E82" opacity="0.5" style={{ mixBlendMode: 'multiply' }}>
        <circle
          cx="315.728"
          cy="81.333"
          r="1.915"
          transform="rotate(90 315.728 81.333)"
        ></circle>
        <circle
          cx="294.389"
          cy="81.333"
          r="1.915"
          transform="rotate(90 294.389 81.333)"
        ></circle>
        <circle
          cx="315.728"
          cy="166.693"
          r="1.915"
          transform="rotate(90 315.728 166.693)"
        ></circle>
        <circle
          cx="294.389"
          cy="166.693"
          r="1.915"
          transform="rotate(90 294.389 166.693)"
        ></circle>
        <circle
          cx="315.728"
          cy="124.013"
          r="1.915"
          transform="rotate(90 315.728 124.013)"
        ></circle>
        <circle
          cx="294.389"
          cy="124.013"
          r="1.915"
          transform="rotate(90 294.389 124.013)"
        ></circle>
        <circle
          cx="315.728"
          cy="209.372"
          r="1.915"
          transform="rotate(90 315.728 209.372)"
        ></circle>
        <circle
          cx="294.389"
          cy="209.372"
          r="1.915"
          transform="rotate(90 294.389 209.372)"
        ></circle>
        <circle
          cx="315.728"
          cy="102.674"
          r="1.915"
          transform="rotate(90 315.728 102.674)"
        ></circle>
        <circle
          cx="294.389"
          cy="102.674"
          r="1.915"
          transform="rotate(90 294.389 102.674)"
        ></circle>
        <circle
          cx="315.728"
          cy="188.032"
          r="1.915"
          transform="rotate(90 315.728 188.032)"
        ></circle>
        <circle
          cx="294.389"
          cy="188.032"
          r="1.915"
          transform="rotate(90 294.389 188.032)"
        ></circle>
        <circle
          cx="315.728"
          cy="145.352"
          r="1.915"
          transform="rotate(90 315.728 145.352)"
        ></circle>
        <circle
          cx="294.389"
          cy="145.352"
          r="1.915"
          transform="rotate(90 294.389 145.352)"
        ></circle>
        <circle
          cx="315.728"
          cy="230.71"
          r="1.915"
          transform="rotate(90 315.728 230.71)"
        ></circle>
        <circle
          cx="294.389"
          cy="230.71"
          r="1.915"
          transform="rotate(90 294.389 230.71)"
        ></circle>
        <circle
          cx="273.049"
          cy="81.334"
          r="1.915"
          transform="rotate(90 273.049 81.334)"
        ></circle>
        <circle
          cx="251.709"
          cy="81.334"
          r="1.915"
          transform="rotate(90 251.709 81.334)"
        ></circle>
        <circle
          cx="273.049"
          cy="166.693"
          r="1.915"
          transform="rotate(90 273.049 166.693)"
        ></circle>
        <circle
          cx="251.709"
          cy="166.693"
          r="1.915"
          transform="rotate(90 251.709 166.693)"
        ></circle>
        <circle
          cx="273.049"
          cy="124.013"
          r="1.915"
          transform="rotate(90 273.049 124.013)"
        ></circle>
        <circle
          cx="251.709"
          cy="124.013"
          r="1.915"
          transform="rotate(90 251.709 124.013)"
        ></circle>
        <circle
          cx="273.049"
          cy="209.372"
          r="1.915"
          transform="rotate(90 273.049 209.372)"
        ></circle>
        <circle
          cx="251.709"
          cy="209.373"
          r="1.915"
          transform="rotate(90 251.709 209.373)"
        ></circle>
        <circle
          cx="273.049"
          cy="102.674"
          r="1.915"
          transform="rotate(90 273.049 102.674)"
        ></circle>
        <circle
          cx="251.709"
          cy="102.674"
          r="1.915"
          transform="rotate(90 251.709 102.674)"
        ></circle>
        <circle
          cx="273.049"
          cy="188.032"
          r="1.915"
          transform="rotate(90 273.049 188.032)"
        ></circle>
        <circle
          cx="251.709"
          cy="188.032"
          r="1.915"
          transform="rotate(90 251.709 188.032)"
        ></circle>
        <circle
          cx="273.049"
          cy="145.352"
          r="1.915"
          transform="rotate(90 273.049 145.352)"
        ></circle>
        <circle
          cx="251.709"
          cy="145.352"
          r="1.915"
          transform="rotate(90 251.709 145.352)"
        ></circle>
        <circle
          cx="273.049"
          cy="230.71"
          r="1.915"
          transform="rotate(90 273.049 230.71)"
        ></circle>
        <circle
          cx="251.709"
          cy="230.71"
          r="1.915"
          transform="rotate(90 251.709 230.71)"
        ></circle>
        <circle
          cx="230.364"
          cy="81.333"
          r="1.915"
          transform="rotate(90 230.364 81.333)"
        ></circle>
        <circle
          cx="209.022"
          cy="81.333"
          r="1.915"
          transform="rotate(90 209.022 81.333)"
        ></circle>
        <circle
          cx="230.364"
          cy="166.693"
          r="1.915"
          transform="rotate(90 230.364 166.693)"
        ></circle>
        <circle
          cx="209.022"
          cy="166.693"
          r="1.915"
          transform="rotate(90 209.022 166.693)"
        ></circle>
        <circle
          cx="230.364"
          cy="124.013"
          r="1.915"
          transform="rotate(90 230.364 124.013)"
        ></circle>
        <circle
          cx="209.022"
          cy="124.013"
          r="1.915"
          transform="rotate(90 209.022 124.013)"
        ></circle>
        <circle
          cx="230.364"
          cy="209.372"
          r="1.915"
          transform="rotate(90 230.364 209.372)"
        ></circle>
        <circle
          cx="209.022"
          cy="209.372"
          r="1.915"
          transform="rotate(90 209.022 209.372)"
        ></circle>
        <circle
          cx="230.364"
          cy="102.674"
          r="1.915"
          transform="rotate(90 230.364 102.674)"
        ></circle>
        <circle
          cx="209.022"
          cy="102.674"
          r="1.915"
          transform="rotate(90 209.022 102.674)"
        ></circle>
        <circle
          cx="230.364"
          cy="188.032"
          r="1.915"
          transform="rotate(90 230.364 188.032)"
        ></circle>
        <circle
          cx="209.022"
          cy="188.032"
          r="1.915"
          transform="rotate(90 209.022 188.032)"
        ></circle>
        <circle
          cx="230.364"
          cy="145.352"
          r="1.915"
          transform="rotate(90 230.364 145.352)"
        ></circle>
        <circle
          cx="209.022"
          cy="145.352"
          r="1.915"
          transform="rotate(90 209.022 145.352)"
        ></circle>
        <circle
          cx="230.364"
          cy="230.71"
          r="1.915"
          transform="rotate(90 230.364 230.71)"
        ></circle>
        <circle
          cx="209.022"
          cy="230.71"
          r="1.915"
          transform="rotate(90 209.022 230.71)"
        ></circle>
        <circle
          cx="187.686"
          cy="81.334"
          r="1.915"
          transform="rotate(90 187.686 81.334)"
        ></circle>
        <circle
          cx="166.348"
          cy="81.333"
          r="1.915"
          transform="rotate(90 166.348 81.333)"
        ></circle>
        <circle
          cx="187.686"
          cy="166.695"
          r="1.915"
          transform="rotate(90 187.686 166.695)"
        ></circle>
        <circle
          cx="166.348"
          cy="166.695"
          r="1.915"
          transform="rotate(90 166.348 166.695)"
        ></circle>
        <circle
          cx="187.686"
          cy="124.015"
          r="1.915"
          transform="rotate(90 187.686 124.015)"
        ></circle>
        <circle
          cx="166.348"
          cy="124.015"
          r="1.915"
          transform="rotate(90 166.348 124.015)"
        ></circle>
        <circle
          cx="187.686"
          cy="209.375"
          r="1.915"
          transform="rotate(90 187.686 209.375)"
        ></circle>
        <circle
          cx="166.348"
          cy="209.375"
          r="1.915"
          transform="rotate(90 166.348 209.375)"
        ></circle>
        <circle
          cx="187.686"
          cy="102.676"
          r="1.915"
          transform="rotate(90 187.686 102.676)"
        ></circle>
        <circle
          cx="166.348"
          cy="102.676"
          r="1.915"
          transform="rotate(90 166.348 102.676)"
        ></circle>
        <circle
          cx="187.686"
          cy="188.034"
          r="1.915"
          transform="rotate(90 187.686 188.034)"
        ></circle>
        <circle
          cx="166.348"
          cy="188.034"
          r="1.915"
          transform="rotate(90 166.348 188.034)"
        ></circle>
        <circle
          cx="187.686"
          cy="145.355"
          r="1.915"
          transform="rotate(90 187.686 145.355)"
        ></circle>
        <circle
          cx="166.348"
          cy="145.355"
          r="1.915"
          transform="rotate(90 166.348 145.355)"
        ></circle>
        <circle
          cx="187.686"
          cy="230.713"
          r="1.915"
          transform="rotate(90 187.686 230.713)"
        ></circle>
        <circle
          cx="166.348"
          cy="230.713"
          r="1.915"
          transform="rotate(90 166.348 230.713)"
        ></circle>
      </g>
      <g fill="#601E82" opacity="0.5" style={{ mixBlendMode: 'multiply' }}>
        <circle
          cx="142.532"
          cy="82.166"
          r="1.915"
          transform="rotate(90 142.532 82.166)"
        ></circle>
        <circle
          cx="121.193"
          cy="82.166"
          r="1.915"
          transform="rotate(90 121.193 82.166)"
        ></circle>
        <circle
          cx="142.532"
          cy="167.525"
          r="1.915"
          transform="rotate(90 142.532 167.525)"
        ></circle>
        <circle
          cx="121.193"
          cy="167.525"
          r="1.915"
          transform="rotate(90 121.193 167.525)"
        ></circle>
        <circle
          cx="142.532"
          cy="124.845"
          r="1.915"
          transform="rotate(90 142.532 124.845)"
        ></circle>
        <circle
          cx="121.193"
          cy="124.845"
          r="1.915"
          transform="rotate(90 121.193 124.845)"
        ></circle>
        <circle
          cx="142.532"
          cy="210.203"
          r="1.915"
          transform="rotate(90 142.532 210.203)"
        ></circle>
        <circle
          cx="121.193"
          cy="210.203"
          r="1.915"
          transform="rotate(90 121.193 210.203)"
        ></circle>
        <circle
          cx="142.532"
          cy="103.506"
          r="1.915"
          transform="rotate(90 142.532 103.506)"
        ></circle>
        <circle
          cx="121.193"
          cy="103.506"
          r="1.915"
          transform="rotate(90 121.193 103.506)"
        ></circle>
        <circle
          cx="142.532"
          cy="188.865"
          r="1.915"
          transform="rotate(90 142.532 188.865)"
        ></circle>
        <circle
          cx="121.193"
          cy="188.865"
          r="1.915"
          transform="rotate(90 121.193 188.865)"
        ></circle>
        <circle
          cx="142.532"
          cy="146.185"
          r="1.915"
          transform="rotate(90 142.532 146.185)"
        ></circle>
        <circle
          cx="121.193"
          cy="146.185"
          r="1.915"
          transform="rotate(90 121.193 146.185)"
        ></circle>
        <circle
          cx="142.532"
          cy="231.544"
          r="1.915"
          transform="rotate(90 142.532 231.544)"
        ></circle>
        <circle
          cx="121.193"
          cy="231.544"
          r="1.915"
          transform="rotate(90 121.193 231.544)"
        ></circle>
        <circle
          cx="99.856"
          cy="82.166"
          r="1.915"
          transform="rotate(90 99.856 82.166)"
        ></circle>
        <circle
          cx="78.517"
          cy="82.166"
          r="1.915"
          transform="rotate(90 78.517 82.166)"
        ></circle>
        <circle
          cx="99.856"
          cy="167.525"
          r="1.915"
          transform="rotate(90 99.856 167.525)"
        ></circle>
        <circle
          cx="78.517"
          cy="167.525"
          r="1.915"
          transform="rotate(90 78.517 167.525)"
        ></circle>
        <circle
          cx="99.856"
          cy="124.845"
          r="1.915"
          transform="rotate(90 99.856 124.845)"
        ></circle>
        <circle
          cx="78.517"
          cy="124.845"
          r="1.915"
          transform="rotate(90 78.517 124.845)"
        ></circle>
        <circle
          cx="99.856"
          cy="210.203"
          r="1.915"
          transform="rotate(90 99.856 210.203)"
        ></circle>
        <circle
          cx="78.517"
          cy="210.203"
          r="1.915"
          transform="rotate(90 78.517 210.203)"
        ></circle>
        <circle
          cx="99.856"
          cy="103.506"
          r="1.915"
          transform="rotate(90 99.856 103.506)"
        ></circle>
        <circle
          cx="78.517"
          cy="103.506"
          r="1.915"
          transform="rotate(90 78.517 103.506)"
        ></circle>
        <circle
          cx="99.856"
          cy="188.865"
          r="1.915"
          transform="rotate(90 99.856 188.865)"
        ></circle>
        <circle
          cx="78.517"
          cy="188.865"
          r="1.915"
          transform="rotate(90 78.517 188.865)"
        ></circle>
        <circle
          cx="99.856"
          cy="146.185"
          r="1.915"
          transform="rotate(90 99.856 146.185)"
        ></circle>
        <circle
          cx="78.517"
          cy="146.185"
          r="1.915"
          transform="rotate(90 78.517 146.185)"
        ></circle>
        <circle
          cx="99.856"
          cy="231.542"
          r="1.915"
          transform="rotate(90 99.856 231.542)"
        ></circle>
        <circle
          cx="78.517"
          cy="231.542"
          r="1.915"
          transform="rotate(90 78.517 231.542)"
        ></circle>
        <circle
          cx="57.18"
          cy="82.166"
          r="1.915"
          transform="rotate(90 57.18 82.166)"
        ></circle>
        <circle
          cx="35.841"
          cy="82.166"
          r="1.915"
          transform="rotate(90 35.84 82.166)"
        ></circle>
        <circle
          cx="57.18"
          cy="167.525"
          r="1.915"
          transform="rotate(90 57.18 167.525)"
        ></circle>
        <circle
          cx="35.841"
          cy="167.525"
          r="1.915"
          transform="rotate(90 35.84 167.525)"
        ></circle>
        <circle
          cx="57.18"
          cy="124.845"
          r="1.915"
          transform="rotate(90 57.18 124.845)"
        ></circle>
        <circle
          cx="35.841"
          cy="124.845"
          r="1.915"
          transform="rotate(90 35.84 124.845)"
        ></circle>
        <circle
          cx="57.18"
          cy="210.203"
          r="1.915"
          transform="rotate(90 57.18 210.203)"
        ></circle>
        <circle
          cx="35.841"
          cy="210.203"
          r="1.915"
          transform="rotate(90 35.84 210.203)"
        ></circle>
        <circle
          cx="57.18"
          cy="103.506"
          r="1.915"
          transform="rotate(90 57.18 103.506)"
        ></circle>
        <circle
          cx="35.841"
          cy="103.506"
          r="1.915"
          transform="rotate(90 35.84 103.506)"
        ></circle>
        <circle
          cx="57.18"
          cy="188.865"
          r="1.915"
          transform="rotate(90 57.18 188.865)"
        ></circle>
        <circle
          cx="35.841"
          cy="188.865"
          r="1.915"
          transform="rotate(90 35.84 188.865)"
        ></circle>
        <circle
          cx="57.18"
          cy="146.185"
          r="1.915"
          transform="rotate(90 57.18 146.185)"
        ></circle>
        <circle
          cx="35.841"
          cy="146.185"
          r="1.915"
          transform="rotate(90 35.84 146.185)"
        ></circle>
        <circle
          cx="57.18"
          cy="231.544"
          r="1.915"
          transform="rotate(90 57.18 231.544)"
        ></circle>
        <circle
          cx="35.841"
          cy="231.544"
          r="1.915"
          transform="rotate(90 35.84 231.544)"
        ></circle>
        <circle
          cx="14.496"
          cy="82.166"
          r="1.915"
          transform="rotate(90 14.496 82.166)"
        ></circle>
        <circle
          cx="14.496"
          cy="167.526"
          r="1.915"
          transform="rotate(90 14.496 167.526)"
        ></circle>
        <circle
          cx="14.496"
          cy="124.845"
          r="1.915"
          transform="rotate(90 14.496 124.845)"
        ></circle>
        <circle
          cx="14.496"
          cy="210.203"
          r="1.915"
          transform="rotate(90 14.496 210.203)"
        ></circle>
        <circle
          cx="14.496"
          cy="103.506"
          r="1.915"
          transform="rotate(90 14.496 103.506)"
        ></circle>
        <circle
          cx="14.496"
          cy="188.865"
          r="1.915"
          transform="rotate(90 14.496 188.865)"
        ></circle>
        <circle
          cx="14.496"
          cy="146.185"
          r="1.915"
          transform="rotate(90 14.496 146.185)"
        ></circle>
        <circle
          cx="14.496"
          cy="231.544"
          r="1.915"
          transform="rotate(90 14.496 231.544)"
        ></circle>
      </g>
    </g>
    <g clipPath="url(#clip1_19942_17131)">
      <path
        fill="#311F6E"
        d="M202.623 413.961c62.148 0 112.528-6.737 112.528-15.047s-50.38-15.047-112.528-15.047-112.528 6.737-112.528 15.047 50.38 15.047 112.528 15.047"
        opacity="0.3"
      ></path>
      <g clipPath="url(#clip2_19942_17131)">
        <path
          fill="#fff"
          d="M195.388 283.321c.493 7.428 1.5 30.081 2.601 53.087 1.328 27.798 2.793 56.107 3.681 58.654 2.662 7.636 9.575 8.303 9.575 8.303h27.1s8.283-.012 11.927-4.413c5.983-7.232-.101-15.101-.101-15.101s.458-32.793.757-62.811c.124-12.9.22-25.293.238-34.346l-55.776-3.373z"
        ></path>
        <path
          fill="#371A45"
          d="M195.388 283.321c.493 7.428 1.5 30.081 2.601 53.087 20.776 1.326 37.634-9.41 52.936-15.368.124-12.9.22-25.293.238-34.346l-55.777-3.373zM200.747 386.693c.346 4.597.662 7.612.924 8.368 2.661 7.636 9.575 8.304 9.575 8.304h27.099s8.283-.012 11.927-4.413c2.989-3.609 2.966-7.381 2.192-10.247q0 .008-.012.018c-3.947 4.764-12.922 4.776-12.922 4.776h-29.362s-6.116-.588-9.419-6.804z"
          opacity="0.29"
        ></path>
        <path
          fill="#371A45"
          d="M211.246 405.98h27.099c.977 0 9.641-.161 13.944-5.361 5.373-6.495 2.929-13.916.514-17.582.109-7.906.933-68.396.982-96.336a2.62 2.62 0 0 0-2.617-2.624h-.005a2.62 2.62 0 0 0-2.621 2.614c-.053 29.744-.984 96.455-.994 97.125a2.62 2.62 0 0 0 .547 1.638c.186.247 4.7 6.334.153 11.829-2.325 2.809-7.942 3.45-9.911 3.46H211.41c-.712-.109-5.341-1.023-7.264-6.543-1.004-3.569-3.063-47.61-4.428-76.752-.726-15.517-1.353-28.918-1.713-34.303a2.624 2.624 0 0 0-5.234.349c.358 5.332.982 18.708 1.707 34.197 2.37 50.639 3.679 75.26 4.713 78.229 3.176 9.118 11.45 10.015 11.801 10.048q.127.013.25.012z"
        ></path>
        <path
          fill="#fff"
          d="M135.373 386.55c-3.167 3.92-3.59 9.588.744 13.573 4.34 3.984 20.674 3.17 20.674 3.17h23.634s6.913-.666 9.575-8.304c2.06-5.924.839-30.379 1.578-58.301.154-5.93.399-12.014.78-18.111l-59.425-28.916c.923 10.89 1.935 21.547 2.959 31.533 3.501 34.281 7.056 60.575 7.056 60.575s-4.859 1.415-7.575 4.781"
        ></path>
        <path
          fill="#371A45"
          d="M136.116 400.124c4.339 3.98 20.672 3.169 20.672 3.169h23.633s6.915-.668 9.576-8.3c.334-.956.584-2.396.767-4.262-3.028 2.344-6.096 2.639-6.096 2.639h-26.721s-18.462.917-23.371-3.587a10 10 0 0 1-.734-.74c-1.62 3.594-1.205 7.881 2.274 11.083zM132.931 289.661c.923 10.89 1.935 21.547 2.959 31.533 3.989 1.38 8.498 3.266 13.427 5.859 17.274 9.093 35.626 9.742 42.259 9.635.154-5.93.399-12.014.78-18.111z"
          opacity="0.29"
        ></path>
        <path
          fill="#371A45"
          d="M153.015 406c1.739 0 3.113-.052 3.84-.087h23.568q.126 0 .252-.012c.351-.033 8.626-.93 11.801-10.048 1.44-4.134 1.442-14.481 1.444-30.142.001-13.471.005-30.237 1.055-46.966a2.62 2.62 0 0 0-5.231-.328c-1.061 16.891-1.064 33.747-1.066 47.291-.002 13.481-.004 25.124-1.153 28.423-1.912 5.487-6.497 6.424-7.271 6.543h-23.463q-.065-.001-.129.004c-6.332.311-16.336-.252-18.764-2.485-3.394-3.115-2.688-7.267-.482-10.001 2.15-2.664 6.232-3.9 6.274-3.911a2.63 2.63 0 0 0 1.858-2.864c-.059-.437-5.962-44.329-10.002-91.975a2.62 2.62 0 0 0-5.225.442c3.553 41.91 8.545 80.923 9.757 90.174-1.935.846-4.77 2.402-6.746 4.85-4.353 5.394-3.925 12.604 1.016 17.142 3.709 3.407 13.076 3.95 18.669 3.95z"
        ></path>
        <path
          fill="#371A45"
          d="M168.485 359.24c12.588 0 22.645-2.043 23.374-2.196a2.618 2.618 0 1 0-1.069-5.126c-.299.062-30.22 6.122-50.722-2.922a2.62 2.62 0 1 0-2.118 4.791c9.606 4.238 20.826 5.453 30.537 5.453zM222.216 355.135c17.518 0 28.691-4.017 29.339-4.257a2.62 2.62 0 0 0-1.813-4.913c-.21.077-21.316 7.602-50.653 1.534a2.618 2.618 0 1 0-1.062 5.126c8.921 1.848 17.079 2.51 24.191 2.51zM251.851 381.845c-8.504-6.216-28.007-2.709-38.165-1.474-1.635.258-1.387 2.701.284 2.604 3.071-.223 6.139-.414 9.201-.538 6.727-.227 13.627-.376 20.162 1.103 1.826.449 3.986 1.254 5.247 2.4 2.776 2.23 6.074-1.877 3.27-4.097zM143.561 384.314c5.53-1.74 11.702-2.067 17.563-1.899 1.609.042 1.856-2.312.275-2.604-6.307-1.073-12.617-1.629-19.067-.588-3.659.746-2.376 6.09 1.229 5.091"
        ></path>
        <path
          fill="#D8D8D8"
          d="M142.478 274.388s-25.913 14.075-37.041 3.168c-17.165-16.824 3.305-50.617 3.305-50.617l33.734 47.449z"
        ></path>
        <path
          fill="#371A45"
          d="M117.332 284.297c12.19 0 25.604-7.177 26.398-7.609a2.617 2.617 0 0 0 .886-3.818l-33.735-47.448a2.66 2.66 0 0 0-2.234-1.102 2.62 2.62 0 0 0-2.146 1.261c-.88 1.452-21.333 35.773-2.899 53.841 3.669 3.596 8.592 4.871 13.731 4.871zm21.24-10.878c-8.193 3.844-24.203 9.223-31.299 2.269-12.385-12.14-2.697-34.991 1.769-43.804z"
        ></path>
        <path
          fill="#fff"
          d="M212.756 213.861c4.384 6.494 10.46 13.591 15.252 18.88 4.352 4.798 7.641 8.11 7.641 8.11s57.238 4 72.986-38.111c1.835-4.904 2.983-9.846 3.599-14.69 3.44-26.695-8.933-50.596-8.933-50.596l-30.915 14.553-8.92 29.615s-6.633 3.169-18.129 6.887c-8.894 2.887-20.698 6.1-34.599 8.437-6.508 1.093-3.67 8.491 2.02 16.915z"
        ></path>
        <path
          fill="#371A45"
          d="M228.007 232.741c4.353 4.798 7.642 8.11 7.642 8.11s57.237 4 72.986-38.111c1.834-4.904 2.982-9.846 3.598-14.69-3.014 8.333-18.365 42.097-69.163 43.519-6.115.171-11.076.582-15.061 1.172zM212.756 213.861c13.81-5.028 25.279-14.015 32.58-25.354-8.894 2.887-20.698 6.101-34.599 8.437-6.509 1.093-3.67 8.491 2.019 16.915z"
          opacity="0.29"
        ></path>
        <path
          fill="#D8D8D8"
          d="M148.888 207.642c10.847 6.715 24.086 10.663 38.366 10.663 17.12 0 32.731-5.676 44.542-15.003l-18.431-29.217-64.197 33.066c-.092.164-.19.326-.282.491z"
        ></path>
        <path
          fill="#371A45"
          d="M239.703 243.579c5.537 0 15.852-.487 27.198-3.459 22.159-5.805 37.439-18.413 44.191-36.461 12.207-32.635-4.735-66.005-5.463-67.41a2.624 2.624 0 0 0-3.533-1.123 2.617 2.617 0 0 0-1.125 3.528c.208.406 16.573 32.791 5.208 63.171-13.779 36.838-61.953 36.706-69.388 36.46-14.384-14.593-28.666-33.024-27.083-37.942.057-.18.193-.601 1.463-.815 31.853-5.36 52.56-15.133 53.426-15.545a2.62 2.62 0 0 0 1.379-1.608l8.919-29.612a2.62 2.62 0 0 0-1.755-3.262 2.625 2.625 0 0 0-3.266 1.753l-8.577 28.474c-4.567 2.022-23.84 10.067-50.998 14.637-3.842.645-5.148 3.019-5.586 4.375-1.017 3.165-.722 9.376 13.528 26.725 7.489 9.116 15.466 17.155 15.545 17.235a2.62 2.62 0 0 0 1.677.766c.188.014 1.717.114 4.236.114z"
        ></path>
        <path
          fill="#fff"
          d="M129.999 281.346c.668 12.189 3.971 23.234 11.175 31.225 25.082 27.835 92.254 22.395 105.73-12.712 2.209-5.749 3.021-13.609 2.863-22.427-.386-21.76-6.692-49.365-12.558-65.547l-23.843-37.798-64.198 33.065c-10.29 17.825-20.574 48.534-19.17 74.194z"
        ></path>
        <path
          fill="#371A45"
          d="M148.888 207.642c10.847 6.715 24.086 10.663 38.366 10.663 17.12 0 32.731-5.676 44.542-15.003l-18.431-29.217-64.197 33.066c-.092.164-.19.326-.282.491zM129.999 281.346c.668 12.189 3.971 23.234 11.175 31.225 25.082 27.835 92.254 22.395 105.73-12.712 2.209-5.749 3.021-13.609 2.863-22.427-23.889 4.307-82.01 13.02-119.768 3.914"
          opacity="0.29"
        ></path>
        <path
          fill="#371A45"
          d="M246.432 304.085c11.604-18.737 4.159-52.839-2.09-72.831-.5-1.497-2.808-.981-2.544.628 2.066 11.916 3.808 23.906 4.667 35.936.594 8.913 1.244 17.82-.109 26.634a37 37 0 0 1-2.261 8.453c-.547 1.448 1.491 2.487 2.337 1.178zM169.269 329.372c11.465 4.736 25.193 4.61 36.858.617 1.393-.517.982-2.617-.501-2.572-8.662.337-18.172.469-26.772-.084-2.969-.164-5.94-.382-8.989-.509-1.454-.066-1.924 1.969-.596 2.548M146.895 205.841c-14.709 25.232-26.8 67.718-15.179 95.8 1.977 4.452 4.783 8.638 8.481 11.808a1.322 1.322 0 0 0 1.948-1.751c-15.26-23.269-9.457-53.283-1.48-78.198 2.904-8.647 6.435-17.118 10.782-25.06 1.546-2.988-2.704-5.486-4.552-2.6z"
        ></path>
        <path
          fill="#fff"
          d="M136.621 289.301c-4.808 0-8.706-3.893-8.706-8.696 0-4.802 3.898-8.695 8.706-8.695s8.706 3.893 8.706 8.695-3.898 8.696-8.706 8.696"
        ></path>
        <path
          fill="#371A45"
          d="M136.622 291.919c6.245 0 11.327-5.074 11.327-11.314s-5.08-11.314-11.327-11.314-11.328 5.076-11.328 11.314 5.082 11.314 11.328 11.314m0-17.391c3.355 0 6.084 2.726 6.084 6.077s-2.729 6.077-6.084 6.077-6.085-2.726-6.085-6.077 2.73-6.077 6.085-6.077"
        ></path>
        <path
          fill="#fff"
          d="m145.413 306.162 49.134 1.007c5.816.121 10.676-4.534 10.797-10.35l.536-26.115c.104-5.091-3.458-9.441-8.253-10.526a10.4 10.4 0 0 0-2.11-.258l-49.125-1.008c-5.823-.12-10.683 4.535-10.797 10.351l-.43 20.683-.114 5.432c-.121 5.815 4.548 10.661 10.362 10.784"
        ></path>
        <path
          fill="#fff"
          d="m145.413 306.162 49.134 1.007c5.816.121 10.676-4.534 10.797-10.35l.536-26.115c.104-5.091-3.458-9.441-8.253-10.526a10.4 10.4 0 0 0-2.11-.258l-49.125-1.008c-5.823-.12-10.683 4.535-10.797 10.351l-.43 20.683-.114 5.432c-.121 5.815 4.548 10.661 10.362 10.784"
        ></path>
        <path
          fill="#fff"
          d="m145.413 306.162 49.134 1.007c5.816.121 10.676-4.534 10.797-10.35l.536-26.115c.104-5.091-3.458-9.441-8.253-10.526a10.4 10.4 0 0 0-2.11-.258l-49.125-1.008c-5.823-.12-10.683 4.535-10.797 10.351l-.43 20.683-.114 5.432c-.121 5.815 4.548 10.661 10.362 10.784"
        ></path>
        <path
          fill="#371A45"
          d="m145.339 306.165 49.134 1.008c5.815.12 10.676-4.535 10.797-10.35l.535-26.115c.104-5.091-3.457-9.442-8.252-10.526-2.828 1.793-4.624 2.94-4.624 2.94-2.823 20.913-9.015 31.243-35.086 31.134-11.254-.05-18.32-1.909-22.752-4.307l-.114 5.432c-.121 5.815 4.548 10.662 10.362 10.784"
          opacity="0.29"
        ></path>
        <path
          fill="#371A45"
          d="M192.579 309.748c8.366 0 15.255-6.708 15.428-15.1l.447-21.662c.174-8.499-6.605-15.555-15.114-15.73l-44.669-.917c-4.116-.094-8.012 1.444-10.994 4.302s-4.669 6.692-4.755 10.794l-.446 21.663c-.175 8.497 6.604 15.553 15.114 15.729l44.669.917q.16.004.32.004m-44.229-48.175h.211l44.67.917c2.707.055 5.24 1.17 7.131 3.14 1.892 1.968 2.904 4.543 2.848 7.247l-.446 21.662c-.116 5.611-4.763 10.067-10.398 9.968l-44.669-.917c-5.618-.115-10.096-4.774-9.98-10.385l.447-21.662a10.1 10.1 0 0 1 3.143-7.125 10.12 10.12 0 0 1 7.043-2.847z"
        ></path>
        <path
          fill="#fff"
          d="m162.795 306.338.094.188 31.659.647c4.262.082 8.008-2.389 9.75-6.015a10.3 10.3 0 0 0 1.038-4.335l.243-11.603.081-3.829.134-6.722.084-3.961c.117-5.817-4.544-10.664-10.361-10.786l-10.616-.213c4.874 1.021 8.509 5.408 8.409 10.544l-.156 7.752-.135 6.357-.241 12.02c-.126 5.802-4.985 10.462-10.799 10.348l-19.183-.392z"
        ></path>
        <path
          fill="#371A45"
          d="m157.78 306.232.094.188 36.675.753c4.261.082 8.007-2.389 9.749-6.015a10.3 10.3 0 0 0 1.038-4.335l.243-11.603.081-3.829.134-6.722c-2.123-.337-4.953-.102-6.553 3.009-2.218 4.31-4.444 2.813-6.087.329l-.134 6.357-.242 12.019c-.126 5.802-4.985 10.463-10.799 10.349l-24.198-.498z"
          opacity="0.29"
        ></path>
        <path
          fill="#371A45"
          d="M194.773 309.793c3.408 0 6.637-1.301 9.121-3.681 2.551-2.446 3.994-5.727 4.065-9.236l.537-26.118c.074-3.509-1.234-6.847-3.682-9.395a13.13 13.13 0 0 0-9.247-4.063l-10.614-.215-.594 5.18c3.743.788 6.403 4.126 6.324 7.94l-.537 26.118a7.89 7.89 0 0 1-2.455 5.566 7.92 7.92 0 0 1-5.662 2.219l-31.92-.466-2.63-.053-.048 2.633c-.027 1.906 1.29 2.444 2.586 2.604v.052l44.473.912.282.003zm-1.223-47.296 1.91.038a7.93 7.93 0 0 1 5.572 2.454 7.87 7.87 0 0 1 2.222 5.658l-.537 26.122a7.88 7.88 0 0 1-2.454 5.564 7.9 7.9 0 0 1-5.662 2.221l-2.203-.045a13.04 13.04 0 0 0 2.991-8.08l.537-26.117a13.13 13.13 0 0 0-2.378-7.815z"
        ></path>
        <path
          fill="#22A7F0"
          d="M173.663 283.132c-.016.965 1.23 8.782 1.23 8.782l5.51.082c3.793.056 6.927-3.822 6.999-8.662.072-4.839-2.946-8.807-6.739-8.864l-5.51-.083s-1.467 7.17-1.489 8.743z"
        ></path>
        <path
          fill="#1C7AEA"
          d="M173.663 283.132c-.016.965 1.23 8.782 1.23 8.782l5.51.082c3.793.056 6.927-3.822 6.999-8.662.072-4.839-2.946-8.807-6.739-8.864l-5.51-.083s-1.467 7.17-1.489 8.743z"
        ></path>
        <path
          fill="#371A45"
          d="M180.485 293.959c2.411 0 4.658-1.143 6.341-3.225 1.597-1.977 2.499-4.593 2.54-7.369.04-2.778-.784-5.42-2.32-7.444-1.646-2.166-3.904-3.378-6.356-3.415l-3.845-.057h-.059a3.96 3.96 0 0 0-3.889 3.199c-.782 3.992-1.186 6.499-1.2 7.455-.003.283-.013.947.994 7.452a3.95 3.95 0 0 0 3.855 3.349l3.827.057h.114zm-3.729-17.558 3.875.033c1.219.019 2.384.679 3.283 1.863 1.009 1.326 1.548 3.106 1.521 5.009-.029 1.904-.621 3.666-1.668 4.962-.934 1.155-2.132 1.779-3.335 1.764l-3.827-.057c-.71-4.424-.964-6.436-.975-6.828.013-.545.315-2.597 1.128-6.746z"
        ></path>
        <path
          fill="#22A7F0"
          d="M174.882 291.91c-3.793-.056-6.81-4.025-6.738-8.865s3.205-8.717 6.999-8.661 6.81 4.025 6.739 8.865c-.072 4.84-3.206 8.718-7 8.661"
        ></path>
        <path
          fill="#fff"
          d="M181.89 283.255q-.007.548-.067 1.068c-.671-3.85-3.338-6.746-6.574-6.794-3.513-.052-6.452 3.264-6.932 7.592a10.6 10.6 0 0 1-.163-2.072c.072-4.84 3.204-8.714 6.999-8.66s6.81 4.027 6.737 8.866"
          opacity="0.7"
        ></path>
        <path
          fill="#1C7AEA"
          d="M181.89 283.256c-.073 4.834-3.205 8.713-6.999 8.66-3.795-.061-6.81-4.027-6.738-8.867a11 11 0 0 1 .254-2.206c.953 3.279 3.409 5.623 6.317 5.665 3.193.05 5.922-2.689 6.746-6.449.285.99.435 2.068.42 3.195z"
        ></path>
        <path
          fill="#371A45"
          d="M174.971 293.879c4.824 0 8.799-4.726 8.886-10.597.087-5.914-3.805-10.786-8.676-10.858h-.109c-4.824 0-8.798 4.726-8.885 10.597-.088 5.914 3.805 10.786 8.675 10.858zm.103-17.529h.05c2.657.039 4.856 3.187 4.802 6.872-.054 3.663-2.318 6.727-4.955 6.727h-.05c-2.657-.038-4.856-3.186-4.802-6.871.054-3.663 2.318-6.728 4.955-6.728M160.69 308.251h.007a1.965 1.965 0 0 0 1.96-1.97l-.04-13.217a11.06 11.06 0 0 0-5.003-9.235 7.13 7.13 0 0 1-3.231-6.11l.319-18.629a1.964 1.964 0 0 0-1.932-1.996 1.973 1.973 0 0 0-1.999 1.929l-.319 18.628a11.04 11.04 0 0 0 5.001 9.459 7.15 7.15 0 0 1 3.233 5.966l.04 13.217a1.965 1.965 0 0 0 1.966 1.958z"
        ></path>
        <path
          fill="#fff"
          d="M247.555 271.372s6.556 2.075 7.39 7.838c1.166 8.072-5.878 11.764-5.878 11.764s-28.608 7.609-43.696.942c0 0-9.795-8.007.511-17.021 0 0 22.238 2.181 41.674-3.523z"
        ></path>
        <path
          fill="#371A45"
          d="M205.37 291.917c15.087 6.664 43.695-.942 43.695-.942s7.046-3.691 5.88-11.763a8.05 8.05 0 0 0-1.173-3.162c-.564 1.916-1.595 3.415-2.617 4.518-1.351 1.459-3.132 2.438-5.07 2.887-6.41 1.482-25.92 5.393-38.768 1.462a9.77 9.77 0 0 1-5.554-4.359l-.037-.064c-2.399 6.488 3.644 11.423 3.644 11.423"
          opacity="0.29"
        ></path>
        <path
          fill="#371A45"
          d="M221.813 297.272c13.536 0 27.104-3.549 27.928-3.769l.284-.075.26-.136c.354-.186 8.669-4.665 7.254-14.457-1.055-7.304-8.86-9.856-9.192-9.96l-.764-.243-.769.227c-18.699 5.488-40.463 3.45-40.681 3.43l-1.13-.109-.854.746c-3.786 3.312-5.675 6.957-5.609 10.832.101 5.975 4.96 10.015 5.166 10.185l.275.224.326.143c4.983 2.201 11.238 2.962 17.502 2.962zm26.255-8.746c-3.127.786-27.937 6.733-41.267 1.154-.735-.7-2.983-3.077-3.016-6.042-.022-2.009.995-4.035 3.026-6.037 4.872.367 23.493 1.343 40.613-3.468 1.388.618 4.474 2.325 4.926 5.45.785 5.441-3.278 8.32-4.282 8.941z"
        ></path>
        <path
          fill="#fff"
          d="M75.22 112.888c.474 11.297 2.676 22.17 7.728 26.365 3.629 3.012 30.804 5.916 30.804 5.916l-13.624-72.303s-1.844.317-4.46.912c-5.432 1.244-14.199 3.721-16.515 7.151-2.517 3.724-4.508 18.162-3.932 31.959z"
        ></path>
        <path
          fill="#371A45"
          d="M75.22 112.888c.474 11.297 2.676 22.17 7.728 26.365 3.629 3.012 30.804 5.916 30.804 5.916l-13.624-72.303s-1.844.317-4.46.912c-3.255 8.288-7.106 20.876-8.232 36.054-.779 10.478-8.028 10.437-12.215 3.056z"
          opacity="0.29"
        ></path>
        <path
          fill="#371944"
          d="m116.979 148.151-14.754-78.298-2.54.433c-3.113.53-18.84 3.46-22.707 9.181-3.046 4.507-4.579 18.662-4.473 29.696.089 9.343 1.346 25.937 8.766 32.1 3.931 3.266 25.64 5.81 32.201 6.513zM98.034 75.927l12.484 66.244c-11.647-1.384-24.025-3.513-25.902-4.94-9.906-8.229-7.32-48.879-3.294-54.833 1.707-2.525 10.075-5.1 16.71-6.471z"
        ></path>
        <path
          fill="#fff"
          d="M181.718 205.345c-54.113 0-97.98-43.814-97.98-97.862s43.867-97.861 97.98-97.861c54.112 0 97.978 43.814 97.978 97.861s-43.866 97.862-97.978 97.862"
        ></path>
        <path
          fill="#D8D8D8"
          d="M273.839 140.867c-14.354 39.587-53.392 66.82-97.638 64.326-54.027-3.047-95.354-49.263-92.304-103.223 1.19-21.073 8.97-40.214 21.225-55.524 5.596 49.14 65.329 90.42 140.404 94.651 9.647.543 19.12.451 28.311-.23z"
        ></path>
        <path
          fill="#371A45"
          d="M181.847 207.969c24.733 0 48.289-8.956 66.884-25.544 20.042-17.879 31.914-42.483 33.428-69.279 1.513-26.797-7.51-52.578-25.41-72.596S214.217 8.675 187.387 7.163c-26.824-1.507-52.641 7.5-72.683 25.379C94.662 50.42 82.79 75.025 81.277 101.82c-1.514 26.797 7.509 52.578 25.409 72.596s42.534 31.875 69.362 33.387c1.937.109 3.874.165 5.799.165m-.19-195.732c1.804 0 3.61.05 5.433.154 52.499 2.96 92.798 48.026 89.834 100.46-2.86 50.614-45.009 89.874-95.146 89.879-1.804 0-3.61-.051-5.433-.153-52.497-2.959-92.796-48.025-89.832-100.46 2.858-50.614 45.007-89.878 95.144-89.878z"
        ></path>
        <path
          fill="#371A45"
          d="M181.781 207.97c52.902 0 97.363-41.42 100.38-94.82 3.126-55.32-39.388-102.866-94.773-105.987-24.867-1.404-49.249 6.398-68.648 21.965-22.205 17.809-35.86 44.306-37.466 72.694-.03.538-.053 1.083-.075 1.621a100.6 100.6 0 0 0 1.499 21.896c5.747 31.959 26.784 59.299 56.275 73.134 11.648 5.464 24.122 8.605 37.076 9.334 1.92.108 3.834.161 5.734.161zm95.145-95.117c-1.435 25.399-12.688 48.719-31.686 65.668-19 16.948-43.471 25.498-68.896 24.058-12.28-.693-24.105-3.668-35.142-8.847-27.956-13.114-47.897-39.03-53.343-69.32a95.3 95.3 0 0 1-1.42-20.754c.02-.51.044-1.03.072-1.541 1.52-26.91 14.465-52.024 35.513-68.905 18.389-14.756 41.501-22.152 65.071-20.82 52.492 2.958 92.789 48.03 89.831 100.461"
        ></path>
        <path
          fill="#371A45"
          d="M173.487 204.109c53.021 0 97.368-38.77 100.203-88.909 1.426-25.255-7.763-49.583-25.876-68.503-18.067-18.87-42.86-30.098-69.81-31.619-20.898-1.175-41.274 3.603-58.938 13.824q-.172.099-.327.225c-22.205 17.81-35.86 44.305-37.465 72.695-.029.511-.05 1.029-.073 1.541-.031.32-.05.635-.068.957a80.6 80.6 0 0 0 1.577 21.091c5.734 31.885 26.771 59.227 56.261 73.062q.14.066.283.114c9.012 3.008 18.456 4.807 28.075 5.35q3.097.173 6.156.174zM171.8 20.141q2.946 0 5.908.168c25.619 1.445 49.171 12.102 66.316 30.01 17.099 17.858 25.775 40.794 24.431 64.586-2.676 47.356-44.708 83.966-94.97 83.969-1.943 0-3.892-.054-5.859-.166-9.102-.513-18.038-2.208-26.562-5.039-27.882-13.136-47.766-39.015-53.206-69.257a75.5 75.5 0 0 1-1.49-19.773c.016-.287.03-.545.058-.81q.008-.085.012-.169c.022-.512.044-1.031.072-1.543 1.517-26.842 14.401-51.901 35.358-68.78 15.065-8.68 32.215-13.196 49.932-13.196"
        ></path>
        <path
          fill="#371A45"
          d="M261.584 114.518c-2.59 45.816-44.499 80.722-93.606 77.952-41.627-2.347-75.066-31.012-82.702-67.596a77.8 77.8 0 0 1-1.528-20.383c.019-.317.036-.625.07-.941 3.096-45.354 44.769-79.754 93.537-77.005 49.105 2.768 86.819 42.155 84.229 87.971z"
        ></path>
        <path
          fill="#220F2D"
          d="M261.585 114.518c-2.59 45.816-44.5 80.722-93.607 77.952-36.931-2.082-67.418-24.879-79.153-55.552 16.564 19.622 41.676 32.825 70.394 34.445 50.859 2.866 94.588-31.626 100.652-78.082a77.6 77.6 0 0 1 1.712 21.237z"
        ></path>
        <path
          fill="#fff"
          d="M213.382 61.822s-42.803-12.806-74.701 2.379l-.101.048a41.6 41.6 0 0 0-16.542 14.253c-5.463 8.084-8.021 14.449-10.054 20.139-6.173 17.307-5.774 26.57-5.774 26.57-6.747-12.651-8.685-25.01-3.689-39.477a70 70 0 0 1 1.69-4.347q.215-.507.45-1.006-.007-.01.012-.018a102 102 0 0 1 6.307-10.62c8.208-12.084 22.072-26.739 41.902-28.844 37.081-3.938 62.595 19.624 60.5 20.923M228.731 127.04c-7.395-.508-12.917-7.797-12.334-16.282s7.052-14.952 14.448-14.445c7.395.508 12.917 7.798 12.334 16.282s-7.052 14.952-14.448 14.445M222.104 143.269c-.086-3.512 2.324-6.419 5.383-6.494s5.608 2.712 5.694 6.223c.086 3.512-2.324 6.419-5.383 6.494s-5.608-2.712-5.694-6.223"
          opacity="0.5"
        ></path>
        <path
          fill="#fff"
          d="m284.349 99.82-6.675 37.976-4.158 23.656-13.402-.119-1.267-.014c-5.779-1.135-7.827-17.07-4.574-35.566.071-.416.151-.843.232-1.259 3.35-17.866 10.473-31.265 16.118-30.147l1.185.47z"
        ></path>
        <path
          fill="#371A45"
          d="m277.673 137.796-4.157 23.656-13.402-.119-1.268-.014c-5.778-1.135-7.826-17.07-4.573-35.566.07-.416.151-.843.231-1.259l1.011-2.153s.923 8.133 4.609 9.601c3.897 1.561 7.452-7.829 7.452-7.829l10.099 13.683z"
          opacity="0.29"
        ></path>
        <path
          fill="#371A45"
          d="M273.516 164.074a2.62 2.62 0 0 0 2.582-2.166l10.834-61.633a2.616 2.616 0 0 0-1.61-2.883l-13.724-5.48a2.7 2.7 0 0 0-.465-.14c-1.229-.243-3.159-.213-5.391 1.315-5.791 3.96-11.304 16.608-14.048 32.217-3.147 17.899-1.875 36.901 6.644 38.589q.241.048.487.05l14.668.131zm7.884-62.609-10.082 57.352-12.125-.109c-2.664-.979-5.786-12.868-2.335-32.499 2.716-15.443 8.016-26.179 11.848-28.8.292-.2.816-.51 1.25-.512z"
        ></path>
        <path
          fill="#fff"
          d="M289.243 128.097s-.01.01-.01.02a88 88 0 0 1-.669 4.434c-2.99 17.012-9.723 29.96-15.046 28.901-5.161-1.021-7.099-14.883-4.454-31.297.074-.467.158-.945.242-1.423 2.989-17.012 9.733-29.96 15.047-28.911 4.849.964 6.839 13.22 4.892 28.276z"
        ></path>
        <path
          fill="#D8D8D8"
          d="M289.233 128.117a88 88 0 0 1-.67 4.434c-2.989 17.012-9.723 29.96-15.045 28.901-5.161-1.021-7.1-14.883-4.455-31.297 1.588 6.292 5.158 17.478 10.119 14.975 2.897-1.471 7.009-9.91 10.052-17.012z"
        ></path>
        <path
          fill="#371A45"
          d="M274.122 164.131c1.093 0 2.478-.304 4.008-1.351 5.373-3.677 10.484-15.366 13.016-29.782 2.823-16.056 2.06-34.09-6.284-35.742-1.169-.231-3.007-.203-5.122 1.242-5.373 3.676-10.483 15.368-13.016 29.781-2.823 16.057-2.061 34.09 6.284 35.743a6 6 0 0 0 1.114.107zm11.86-32.038c-2.488 14.154-7.325 23.978-10.816 26.366-.47.322-.886.48-1.138.426-2.128-.42-5.421-11.034-2.14-29.7 2.488-14.154 7.325-23.976 10.816-26.367.47-.32.886-.478 1.14-.426 2.126.421 5.421 11.036 2.14 29.701z"
        ></path>
        <path
          fill="#fff"
          d="M312.778 181.619c.104-18.288-2.721-31.421-9.478-44.167-7.508-14.162-33.498-22.879-38.758-13.997-3.663 6.18 3.029 9.962 5.251 11.01a.65.65 0 0 1 .281.931c-1.341 2.187-5.583 9.783-5.688 18.425-.101 8.199 1.925 15.652 11.982 19.025"
        ></path>
        <path
          fill="#371A45"
          d="M315.4 181.634c.117-13.612-1.56-27.614-7.239-40.173-1.044-2.289-2.046-4.429-3.345-6.624-3.639-5.752-9.225-10.018-15.203-13.066-6.071-2.975-12.857-5.218-19.752-4.279-5.45.663-9.933 5.418-8.818 11.193.834 3.832 4.12 6.38 7.301 7.985l.305.149c-.696-.292-1.277-1.294-1.071-2.141.019-.213.173-.518.24-.634l-.02.034-.166.273c-5.414 8.962-8.113 20.489-3.753 30.45 1.496 3.211 4.164 5.995 7.32 7.541 1.529.816 3.143 1.327 4.746 1.745a1.311 1.311 0 0 0 .834-2.483c-4.048-1.836-7.252-4.499-8.69-8.575-2.634-8.725-.313-18.46 4.192-26.22 1.126-1.714.281-4.113-1.614-4.832-2.187-1.14-4.992-3.006-4.502-5.641.5-2.216 2.175-3.327 4.539-3.676 9.489-.989 20.627 4.737 27.087 11.569 2.528 2.699 4.055 6.02 5.571 9.354 2.779 6.236 4.607 12.858 5.624 19.641.893 6.043 1.189 12.206 1.165 18.391.069 3.427 5.124 3.508 5.244.021h.005z"
        ></path>
        <path
          fill="#fff"
          d="M263.468 181.619s-2.879 10.855-2.879 17.208z"
        ></path>
        <path
          fill="#371A45"
          d="M260.933 180.948c-.79 4.436-1.405 8.825-1.651 13.321-.071 1.502-.104 3.012-.004 4.558a1.313 1.313 0 0 0 2.622 0c.587-5.562 2.343-11.212 4.104-16.543 1.074-3.567-4.238-4.975-5.071-1.336"
        ></path>
        <path
          fill="#DDDCDB"
          d="M125.885 181.926c7.8 0 15.148 6.316 16.406 14.106 1.264 7.797-4.043 14.114-11.842 14.114s-15.155-6.317-16.412-14.114c-1.259-7.79 4.043-14.106 11.848-14.106m-3.38 12.742c.584 3.616 4.369 6.91 7.942 6.91.834 0 1.99-.183 2.734-1.056.623-.731.853-1.839.641-3.125-.584-3.616-4.369-6.905-7.937-6.905-.841 0-1.996.183-2.739 1.051-.618.731-.846 1.839-.641 3.125"
        ></path>
        <path
          fill="#fff"
          d="M125.882 181.922c7.806 0 15.153 6.317 16.412 14.114.02.117.038.236.045.354-2.629-5.911-8.783-10.245-15.238-10.245-7.688 0-12.944 6.127-11.895 13.753a15.2 15.2 0 0 1-1.167-3.862c-1.259-7.797 4.037-14.114 11.843-14.114"
          opacity="0.7"
        ></path>
        <path
          fill="#371A45"
          d="M130.449 212.765c4.52 0 8.519-1.774 11.264-4.993 2.784-3.267 3.909-7.584 3.167-12.157-1.452-8.992-9.973-16.308-18.995-16.308-4.521 0-8.521 1.772-11.264 4.989-2.784 3.266-3.91 7.582-3.172 12.154 1.452 8.996 9.975 16.315 19 16.315m-4.564-28.221c6.559 0 12.758 5.341 13.819 11.906.491 3.036-.214 5.851-1.984 7.929-1.731 2.03-4.312 3.149-7.271 3.149-6.563 0-12.765-5.343-13.824-11.911-.49-3.034.217-5.847 1.988-7.926 1.73-2.029 4.313-3.147 7.272-3.147m4.564 19.654c1.964 0 3.644-.702 4.731-1.98 1.131-1.326 1.568-3.19 1.23-5.245-.78-4.843-5.699-9.099-10.523-9.099-1.964 0-3.644.699-4.732 1.966-1.129 1.338-1.564 3.194-1.237 5.24v.004c.784 4.855 5.705 9.113 10.531 9.113zm-4.564-11.086c2.274 0 4.973 2.374 5.349 4.704.106.641-.013.961-.05 1.013-.03.025-.254.134-.735.134-2.274 0-4.977-2.376-5.354-4.706-.104-.645.018-.972.057-1.024.017-.01.245-.119.733-.119z"
        ></path>
        <path
          fill="#EDAE3E"
          d="m94.54 196.036 15.088 45.96 13.508 41.169h74.12l-8.364-25.485-20.231-61.644z"
        ></path>
        <path
          fill="#D18C28"
          d="m109.628 241.996 13.508 41.169h74.12l-8.364-25.484s-6.488 14.879-27.383 17.635c-11.351 1.492-21.608-6.853-22.494-18.257-.399-5.107-2.012-10.801-6.082-16.104-5.952-7.738-16.044-3.619-23.307 1.041z"
        ></path>
        <path
          fill="#371A45"
          d="M123.134 285.782h74.119a2.615 2.615 0 0 0 2.49-3.433L171.15 195.22a2.62 2.62 0 0 0-2.491-1.804H94.541a2.614 2.614 0 0 0-2.49 3.433l28.592 87.129a2.62 2.62 0 0 0 2.491 1.804m70.5-5.237h-68.602l-26.874-81.892h68.6l26.874 81.892z"
        ></path>
        <path
          fill="#DDDCDB"
          d="M110.029 207.566h44.097l-3.605-11.532h-44.095z"
        ></path>
        <path
          fill="#fff"
          d="m108.554 202.85 1.475 4.714h44.095l-3.605-11.528h-4.844l.819 2.622c.65 2.08-.906 4.192-3.087 4.192h-34.855z"
          opacity="0.7"
        ></path>
        <path
          fill="#371A45"
          d="M110.029 210.185h44.097c.834 0 1.618-.396 2.111-1.068.494-.671.64-1.537.391-2.332l-3.603-11.532a2.62 2.62 0 0 0-2.503-1.839h-44.097c-.834 0-1.618.396-2.111 1.068a2.61 2.61 0 0 0-.391 2.332l3.603 11.532a2.625 2.625 0 0 0 2.503 1.839m40.532-5.237h-38.604l-1.967-6.295h38.604z"
        ></path>
        <path
          fill="#fff"
          d="M98.776 242.108c1.042 6.364 2.182 11.672 3.434 16.018 0 0 0 .007.007.027 3.276 11.437 7.281 16.268 12.072 16.261 23.668-.026 24.074-20.477 17.696-32.141-6.981-12.771-24.852-7.836-24.852-7.836l-3.204-9.773s-7.315 4.229-5.151 17.446z"
        ></path>
        <path
          fill="#371A45"
          d="M102.217 258.152c3.276 11.436 7.281 16.267 12.072 16.261 23.668-.027 24.074-20.478 17.696-32.142 0 0 3.63 18.427-10.25 22.794-14.799 4.649-19.295-6.343-19.518-6.913"
          opacity="0.29"
        ></path>
        <path
          fill="#371A45"
          d="M114.278 277.031h.011c12.469-.013 18.266-5.376 20.935-9.874 4.8-8.092 2.988-18.958-.938-26.138-6.308-11.537-19.819-10.588-25.394-9.635l-2.474-7.54a2.625 2.625 0 0 0-3.805-1.452c-.363.21-8.854 5.306-6.425 20.139 3.907 23.855 9.488 34.498 18.09 34.498zm.731-40.953c5.002 0 11.345 1.36 14.675 7.45 3.11 5.688 4.758 14.674 1.03 20.959-2.872 4.839-8.399 7.299-16.43 7.305h-.003c-3.644 0-8.84-5.225-12.917-30.106-.977-5.96.161-9.743 1.457-11.988l1.821 5.552a2.62 2.62 0 0 0 3.189 1.708c.28-.075 3.333-.88 7.178-.88M285.244 231.908a1.966 1.966 0 0 0 .658-3.816c-23.592-8.358-21.254-42.894-21.227-43.241a1.965 1.965 0 0 0-1.811-2.107 1.96 1.96 0 0 0-2.11 1.807c-.119 1.542-2.605 37.877 23.833 47.243.217.077.44.114.658.114z"
        ></path>
      </g>
    </g>
    <defs>
      <clipPath id="clip0_19942_17131">
        <path fill="#fff" d="M4.5 4h425v427H4.5z"></path>
      </clipPath>
      <clipPath id="clip1_19942_17131">
        <path fill="#fff" d="M.5 0h314.651v414H.5z"></path>
      </clipPath>
      <clipPath id="clip2_19942_17131">
        <path fill="#fff" d="M315.5 7h-243v399h243z"></path>
      </clipPath>
    </defs>
  </svg>
)
