import { ptBR } from 'date-fns/locale/pt-BR'
import { format, formatDistanceStrict, parseISO, isValid } from 'date-fns'

const options = { locale: ptBR }

export const getDateDistanceToNow = (fromDate: string): string => {
  if (!fromDate) {
    return ''
  }

  let result = `há ${formatDistanceStrict(
    parseISO(fromDate),
    new Date(),
    options
  )}`
  if (result.includes('horas')) result = 'hoje'

  return result
}

export const getDateInLongText = (date: string): string => {
  const newDate = new Date(date)

  if (isValid(newDate)) {
    return format(newDate, "dd' de 'MMMM' de 'yyyy", options)
  }

  return ''
}
