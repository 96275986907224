import { Icon } from 'components'
import { useEffect, useState } from 'react'

import styles from './styles'
import type { AlertProps } from './types'

export const Alert = ({
  children,
  color = 'info',
  variant = 'ghost',
  showClose = false,
  isOpen = true,
  className = ''
}: AlertProps) => {
  const isGhost = variant === 'ghost'
  const [open, setOpen] = useState<boolean>(true)

  useEffect(() => {
    open !== isOpen && setOpen(isOpen)
  }, [isOpen])

  if (!open) {
    return <></>
  }

  return (
    <div
      role="alert"
      className={styles.container(color, variant) + ' ' + className}
    >
      <Icon
        face={styles.iconFace(color)}
        className={styles.icon(color, isGhost)}
      />

      <p className="leading-5 font-regular text-[15px]">{children}</p>

      {showClose && (
        <button
          type="button"
          className="ml-auto"
          onClick={() => setOpen(false)}
        >
          <Icon size="sm" face="clear" />
        </button>
      )}
    </div>
  )
}
